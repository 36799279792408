// Source: https://tanstack.com/query/latest/docs/framework/react/examples/nextjs-app-prefetching

import {
  QueryClient,
  QueryClientConfig,
  isServer,
} from "@tanstack/react-query";

function makeQueryClient(config?: QueryClientConfig) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
    ...config,
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient(config?: QueryClientConfig) {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient(config);
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient(config);
    return browserQueryClient;
  }
}
