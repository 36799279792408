import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/opt/buildhome/repo/frontend/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/opt/buildhome/repo/frontend/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/frontend/src/app/body.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/frontend/src/app/globals.css");
