import React from "react";

import { cn } from "@/lib/utils";

interface LogoImageProps {
  className?: string;
}

export function LogoImage({ className }: LogoImageProps) {
  return (
    <svg viewBox="2 3 27 25" className={cn(className)}>
      <path d="m28.414 8-5-5L22 4.414l3 3V12a2.002 2.002 0 0 0 2 2v10.5a1.5 1.5 0 0 1-3 0V16a1 1 0 0 0-1-1h-4V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v21H2v2h19v-2h-2v-9h3v7.4a3.564 3.564 0 0 0 2.765 3.525A3.506 3.506 0 0 0 29 24.5V9.415A2 2 0 0 0 28.414 8ZM15 12H8v-2h7Z" />{" "}
    </svg>
  );
}

export default React.memo(LogoImage);
