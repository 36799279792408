import Link from "next/link";

import {
  PriceCard,
  PriceCardContent,
  PriceCardValue,
  PriceCardBadge,
} from "@/components/price-card";

import { useStationsAveragePrice } from "@/hooks/api";
import { formatPrice } from "@/lib/formatters";

import LogoImage from "@/components/logo-image";

function Header() {
  const { data: averagePrice } = useStationsAveragePrice();

  return (
    <header className="flex h-14 w-full items-center justify-between border-b border-border px-4 md:pl-6">
      <Link href="/" className="flex h-6 items-center gap-2 text-primary">
        <LogoImage className="h-full fill-primary" />
        <span className="-mt-1 inline-flex text-xl font-bold leading-none">
          pompwijzer
        </span>
      </Link>
      <div className="flex items-center gap-4">
        <span className="hidden text-sm text-muted-foreground md:block">
          Landelijke gemiddelde
        </span>
        <PriceCard className="border-none">
          <PriceCardContent className="gap-2">
            <PriceCardValue className="text-xl">
              {formatPrice(averagePrice?.euro95 ?? 0)}
            </PriceCardValue>
            <PriceCardBadge className="rounded-md bg-white px-2 py-1 text-xs font-semibold text-emerald-700">
              Euro95
            </PriceCardBadge>
          </PriceCardContent>
        </PriceCard>
      </div>
    </header>
  );
}

export default Header;
