"use client";

import { useCallback } from "react";

import {
  MutationCache,
  QueryCache,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { H } from "@highlight-run/next/client";

import { GeistSans } from "geist/font/sans";

import { Toaster } from "@/components/ui/toaster";
import Header from "@/components/header";

import { useToast } from "@/hooks/use-toast";

import { getQueryClient } from "@/lib/get-query-client";
import { cn } from "@/lib/utils";

type BodyProps = {
  children: React.ReactNode;
};

export default function Body({ children }: BodyProps) {
  const { toast } = useToast();

  const queryErrorHandler = useCallback(
    (error: Error) => {
      console.error("Query error", error);

      // Report the error to Highlight.io
      H.consumeError(error, "Query error");

      toast({
        title: "Foutmelding",
        description: "Het laden van de gegevens is niet gelukt.",
        variant: "destructive",
        duration: Infinity,
      });
    },
    [toast],
  );

  const queryClient = getQueryClient({
    queryCache: new QueryCache({
      onError: queryErrorHandler,
    }),
    mutationCache: new MutationCache({
      onError: queryErrorHandler,
    }),
  });

  return (
    <body
      className={cn(
        "dark min-h-dvh bg-background font-sans antialiased",
        GeistSans.variable,
      )}
    >
      <QueryClientProvider client={queryClient}>
        <div className="flex h-screen flex-col">
          <Header />
          {children}
          <Toaster />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </body>
  );
}
